import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { LastQuestionComponent } from '@shared/src/components/questionnaire/End/LastQuestionComponent';
import Layout from '../../components/Layout/Layout';

export type LastQuestionPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

export function LastQuestionPage(props: LastQuestionPageProps) {
  const { parcours } = props;

  if (!parcours) {
    return null;
  }

  return (
    <Layout projetId={parcours.projetId}>
      <LastQuestionComponent nextStepUrl={`/parcours/${parcours?.id}/end`} parcours={parcours} />
    </Layout>
  );
}
