import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { ExplanationComponent } from '@shared/src/components/questionnaire/Explanation/ExplanationComponent';
import { SUJET } from '@shared/src/constant/urls';
import { useSujets } from '@shared/src/hooks/useSujets';
import { categoriePartiePrenantesService } from '@shared/src/services/CategoriePartiePrenantesService';
import { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';

export type ExplanationPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

export function ExplanationPage(props: ExplanationPageProps) {
  const { parcours } = props;
  const { sujets } = useSujets(parcours?.id);
  const [category, setCategory] = useState<CategoriePartiePrenanteDto>();
  const categoriePartiePrenante = parcours?.__categoriePartiePrenante__;

  useEffect(() => {
    if (categoriePartiePrenante)
      categoriePartiePrenantesService
        .findOne(categoriePartiePrenante.id)
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setCategory(response.data ?? undefined);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
  }, [categoriePartiePrenante]);

  if (!category || !parcours || !sujets) return null;

  return (
    <Layout maxWidth="md" projetId={parcours.projetId}>
      <ExplanationComponent
        categorie={category}
        sujets={sujets}
        parcours={parcours}
        updateParcours={true}
        nextStepUrl={`/parcours/${parcours.id}${SUJET}/1`}
      />
    </Layout>
  );
}
