import { InscriptionPartiePrenanteDto } from '@shared/src/api/inscription-partie-prenante/dto/inscription-partie-prenante.dto';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { ParcoursListComponent } from '../../components/parcours/ParcoursListComponent';

export type ParcoursHomePageProps = {
  inscription: InscriptionPartiePrenanteDto | null;
};

/**
 * This page is used to resolve the parcours for
 * this user and redirect to the correct state of parcours
 */
export function ParcoursHomePage(props: ParcoursHomePageProps) {
  const { inscription } = props;
  const { projetId } = useParams();

  return (
    <Layout maxWidth="md" projetId={projetId}>
      <ParcoursListComponent inscription={inscription} />
    </Layout>
  );
}
