import { Provider } from '@shared/src/components/providers/ProjetProvider';
import { END, LAST_QUESTION, PROFILING, START, SUJET } from '@shared/src/constant/urls';
import { useParcours } from '@shared/src/hooks/useParcours';
import { BeforeFetchEvent, addEventListener, removeEventListener } from '@shared/src/services/HttpService';
import { useCallback, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { setProjectIdFetchParam } from '../../services/projetService';
import { EndPage } from './EndPage';
import { ExplanationPage } from './ExplanationPage';
import { LastQuestionPage } from './LastQuestionPage';
import { ParcoursPage } from './ParcoursPage';
import { ProfilingPage } from './ProfilingPage';
import { SujetPage } from './SujetPage';

export function ParcoursRouter() {
  const { parcoursId } = useParams();

  const { parcours, isLoading } = useParcours(parcoursId);

  const setupProjetId = useCallback(
    (event: BeforeFetchEvent) => setProjectIdFetchParam(event, parcours?.projetId),
    [parcours],
  );

  useEffect(() => {
    addEventListener('before:fetch', setupProjetId);
    return function () {
      removeEventListener('before:fetch', setupProjetId);
    };
  }, [parcours, setupProjetId]);

  if (isLoading) {
    return null;
  }

  return (
    <Provider projetId={parcours?.projetId}>
      <Routes>
        <Route path="/" element={<ParcoursPage parcours={parcours} />} />
        <Route path={START} element={<ExplanationPage parcours={parcours} />} />
        <Route path={`${SUJET}/:position`} element={<SujetPage parcours={parcours} />} />
        <Route path={PROFILING} element={<ProfilingPage parcours={parcours} />} />
        <Route path={LAST_QUESTION} element={<LastQuestionPage parcours={parcours} />} />
        <Route path={END} element={<EndPage parcours={parcours} />} />
      </Routes>
    </Provider>
  );
}
