import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { InscriptionPartiePrenanteDto } from '@shared/src/api/inscription-partie-prenante/dto/inscription-partie-prenante.dto';
import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { ParcoursStatusComponent } from '@shared/src/components/questionnaire/Status/ParcoursStatusComponent';
import { StatutParcours } from '@shared/src/enum/parcours-partie-prenante.enum';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';

export type ParcoursListComponentProps = {
  inscription: InscriptionPartiePrenanteDto | null;
};

type ParcoursLinkProps = {
  projetId?: string | null;
  parcours: ParcoursPartiePrenanteDto;
};

const ParcoursLink: React.FC<PropsWithChildren<ParcoursLinkProps>> = ({ projetId, parcours }) => {
  const { lang } = useLang();

  if (!projetId) return null;

  return (
    <Box sx={{ height: '5rem' }}>
      <Button
        key={parcours.id}
        href={`/parcours/${parcours.id}`}
        variant="contained"
        sx={{
          backgroundColor: 'primary.light',
          color: 'text.primary',
          ':hover': { backgroundColor: 'secondary.dark' },
          height: '100%',
          zIndex: 2,
          width: 'calc(100% - 5rem)',
        }}
        disabled={parcours.statut === StatutParcours.TERMINE}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: 'calc(100% - 5rem)',
          }}
        >
          <Stack direction="row" gap="0.5rem">
            <Typography>
              <FormattedMessage id="projet.parcours-list.do-as" />
            </Typography>
            <Typography variant="subtitle1">{parcours.__categoriePartiePrenante__.nom[lang]}</Typography>
          </Stack>
          <ParcoursStatusComponent statutParcours={parcours.statut} />
        </Box>
      </Button>
    </Box>
  );
};

export function ParcoursListComponent(props: ParcoursListComponentProps) {
  const { inscription } = props;

  const projetId = inscription?.projetId;
  const parcours = inscription?.__parcoursPartiePrenantes__;

  if (parcours?.length === 1) {
    const partiePrenanteId = parcours[0].id;
    return <Navigate to={`/parcours/${partiePrenanteId}`} />;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h1" sx={{ mb: '4rem' }}>
        <FormattedMessage id="projet.parcours-list.title" />
      </Typography>
      <Box maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        {inscription?.__parcoursPartiePrenantes__?.map((parcours) => {
          return <ParcoursLink key={parcours.id} projetId={projetId} parcours={parcours} />;
        })}
      </Box>
    </Container>
  );
}
