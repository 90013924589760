import { ProtectedRoute } from '@shared/src/components/ProtectedRoute';
import { Provider } from '@shared/src/components/providers/ProjetProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { useInscription } from '@shared/src/hooks/useInscription';
import { BeforeFetchEvent, addEventListener, removeEventListener } from '@shared/src/services/HttpService';
import { useCallback, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { setProjectIdFetchParam } from '../../services/projetService';
import { ParcoursHomePage } from './ParcoursHomePage';

export function ProjetsRouter() {
  const { projetId } = useParams();

  const { inscription, isLoading } = useInscription(projetId);

  const setupProjetId = useCallback((event: BeforeFetchEvent) => setProjectIdFetchParam(event, projetId), [projetId]);

  useEffect(() => {
    addEventListener('before:fetch', setupProjetId);
    return function () {
      removeEventListener('before:fetch', setupProjetId);
    };
  }, [projetId, setupProjetId]);

  if (isLoading) {
    return null;
  }

  return (
    <Provider projetId={projetId}>
      <Routes>
        <Route element={<ProtectedRoute role={[UtilisateurRole.PARTIE_PRENANTE]} />}>
          <Route path="/" element={<ParcoursHomePage inscription={inscription} />} />
        </Route>
      </Routes>
    </Provider>
  );
}
