import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ProtectedRoute } from '@shared/src/components/ProtectedRoute';
import ScrollToTop from '@shared/src/components/ScrollToTop';
import { Provider as UtilisateurProvider } from '@shared/src/components/providers/UtilisateurProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { themes } from '@shared/theme';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthRouter } from './pages/auth';
import { HomePage } from './pages/home';
import { CookiePolicyPage } from './pages/legal/CookiePolicyPage';
import { LegalInformationPage } from './pages/legal/LegalInformationPage';
import { PrivacyPolicyPage } from './pages/legal/PrivacyPolicyPage';
import { NotFoundPage } from './pages/not-found';
import { ParcoursRouter } from './pages/parcours';
import { ProjetsRouter } from './pages/projets';
import { ProjetsPage } from './pages/projets/ProjetsPage';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = prefersDarkMode ? 'frontofficeDark' : 'frontofficeLight';
  const currentTheme = createTheme(themes[theme]);

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <UtilisateurProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route element={<ProtectedRoute role={[UtilisateurRole.PARTIE_PRENANTE]} />}>
                <Route path="/" Component={HomePage} />
              </Route>
              <Route path="/auth/*" Component={AuthRouter} />
              <Route path="/projets" Component={ProjetsPage} />
              <Route path="/projets/:projetId/*" Component={ProjetsRouter} />
              <Route path="/parcours/:parcoursId/*" Component={ParcoursRouter} />
              <Route path="/cookie-policy" Component={CookiePolicyPage} />
              <Route path="/privacy-policy" Component={PrivacyPolicyPage} />
              <Route path="/legal-information" Component={LegalInformationPage} />
              <Route path="*" Component={NotFoundPage} />
            </Routes>
          </BrowserRouter>
        </UtilisateurProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
