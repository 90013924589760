import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { SujetComponent } from '@shared/src/components/questionnaire/Content/SujetComponent';
import { OUTSIDEIN_CATEGORY_GLOBAL_IDS } from '@shared/src/constant/global-constants';
import { ImpactType } from '@shared/src/enum/sous-sujet.enum';
import { useSujets } from '@shared/src/hooks/useSujets';
import Layout from '../../components/Layout/Layout';

export type SujetPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

export function SujetPage(props: SujetPageProps) {
  const { parcours } = props;
  const { sujets, isLoading } = useSujets(parcours?.id);

  if (!parcours) {
    return null;
  }

  if (isLoading || !sujets) {
    return null;
  }

  return (
    <Layout projetId={parcours.projetId}>
      <SujetComponent
        parcours={parcours}
        sujets={sujets}
        type={
          OUTSIDEIN_CATEGORY_GLOBAL_IDS.includes(parcours?.__categoriePartiePrenante__.globalId ?? '')
            ? ImpactType.OUTSIDEIN
            : ImpactType.INSIDEOUT
        }
        useSavedData={true}
        baseUrl={`/parcours/${parcours.id}`}
      />
    </Layout>
  );
}
