import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { EndComponent } from '@shared/src/components/questionnaire/End/EndComponent';
import { useInscription } from '@shared/src/hooks/useInscription';
import Layout from '../../components/Layout/Layout';

export type EndPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

export function EndPage(props: EndPageProps) {
  const { parcours } = props;
  const projetId = parcours?.projetId;
  const { inscription } = useInscription(projetId);
  const nbParcours = inscription?.__parcoursPartiePrenantes__?.length ?? 0;

  return (
    <Layout projetId={projetId}>
      <EndComponent isGoBackLink={nbParcours > 1} projetId={projetId} />
    </Layout>
  );
}
