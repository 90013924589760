import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { EXPERTS_GLOBAL_ID } from '@shared/src/constant/global-constants';
import { LAST_QUESTION, PROFILING, START, SUJET } from '@shared/src/constant/urls';
import { StatutParcours } from '@shared/src/enum/parcours-partie-prenante.enum';
import { useAnsweredSujets } from '@shared/src/hooks/useAnsweredSujets';
import { useSujets } from '@shared/src/hooks/useSujets';
import { Navigate } from 'react-router-dom';
import { NotFoundPage } from '../not-found';

export type ParcoursPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

/**
 * This page is used to resolve the parcours for
 * this user and redirect to the correct state of parcours
 */
export function ParcoursPage(props: ParcoursPageProps) {
  const { parcours } = props;
  const { projet } = useProjet();
  const { sujets } = useSujets(parcours?.id);
  const { answeredSujets, isAnsweredSujetsLoading } = useAnsweredSujets(parcours?.id);

  if (isAnsweredSujetsLoading) {
    return null;
  }

  if (parcours && sujets) {
    switch (parcours.statut) {
      case StatutParcours.A_FAIRE:
        return <Navigate to={`/parcours/${parcours.id}${START}`} />;
      case StatutParcours.DEMARRE:
        if (!answeredSujets) {
          return <Navigate to={`/parcours/${parcours.id}${SUJET}/1`} />;
        } else {
          if (answeredSujets.length < sujets.length) {
            return <Navigate to={`/parcours/${parcours.id}${SUJET}/` + (answeredSujets.length + 1)} />;
          } else {
            if (projet?.settings?.isProfiling && !parcours.genre) {
              return <Navigate to={`/parcours/${parcours.id}${PROFILING}`} />;
            } else {
              if (EXPERTS_GLOBAL_ID.includes(parcours.__categoriePartiePrenante__.globalId ?? '')) {
                return <Navigate to={`/parcours/${parcours.id}${LAST_QUESTION}`} />;
              } else {
                return <Navigate to={`/parcours/${parcours.id}/end`} />;
              }
            }
          }
        }
      case StatutParcours.TERMINE:
        return <Navigate to={`/parcours/${parcours.id}/end`} />;

      default:
        return <NotFoundPage />;
    }
  }
  return <NotFoundPage />;
}
